import { Modal } from "react-bootstrap";
import styled, {css} from "styled-components";
const BoldFont = 'Rubik-Medium';
const RegularFont = 'Rubik-Light';
const MediumFont = 'Rubik-Regular';

const MainContainer = styled.div`
  float: left;
  width: 100%;
  margin-top: 25px;
`;
const GraphContainer = styled.div`
  width: 100%;
  float: left;
  background-color: #fff;
  height: ${({height, customHeight}) => customHeight ? customHeight : height ? '106px' : '300px'};
  ${'' /* margin-bottom: 15px; */}
  padding-bottom: 25px;
  .no-data{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    font-size: 14px;
    color: #282C37;
    font-family: ${BoldFont};
    opacity: 0.5;
  }
  @media (max-width: 1190px){
    height: auto;
  }
  @media (max-width: 750px) {
    height: auto;
  }
`;

const CompanyHeader = styled.div`
  width: 100%;
  background: #005C87;
  border-radius: 6px 6px 0px 0px;
  color: #fff;
  text-transform: capitalize;
  padding: 10px 15px;
  font-family: ${MediumFont};
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 1px;
`;
const TopDepartmentGraph = styled.div`
  width: 90%;
  margin: ${({margin}) => margin ? margin : '0 auto'};
  height: calc(100% - 89px)
`;
const BottomLabelDonutContainer = styled.div`
    width: ${({padding}) => padding ? 'calc(100% - 180px)' : 'calc(100% - 270px)'};
    float: left;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: ${({justifyContent}) => justifyContent ? justifyContent : 'center'};
    text-align: ${(props) => props.Triglycerides ? 'center' : 'left'};
    padding-top: ${({padding}) => padding ? padding : '50px'};
    @media(max-width: 750px) {
      float: none;
      width: 100%;
    };
    @media(max-width: 500px){
      display: block;
      text-align: center;
    };
`;
const NoData = styled.div`
  width: 100%;
  padding: 20px 0;
  text-align: center;
  height: 325px;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${RegularFont};
  font-size: 18px;
`;
const PieChartContainer = styled.div`
   width: 270px;
   float: left;
   height: 90%;
   position: relative;
   @media(max-width: 750px){
    height: 250px;
    float: none;
    margin: 0 auto;
   }
`;
const CompanyName = styled.div`
    display: flex;
    align-items: center;
    padding-top: 10px;
    text-transform: capitalize;
    > span:nth-child(1) {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      float: left;
      margin-bottom: 23px;
    }
    > span:nth-child(2) {
      float: left;
      margin-left: 10px;
      margin-top: 2px;
      width: 156px;
      height: 20px;    
      font-family: 'Rubik-Medium';
      font-size: 16px;
      line-height: 20px; 
      font-weight: 500;
      display: flex;
      align-items: center; 
      color: #005c87;
      margin-bottom: 23px;
      
      @media(max-width: 1160px) {
        max-width: 120px;
      }
     
      @media(max-width: 750px){
        min-height: unset;
      }
      @media(max-width: 500px){
        max-width: none;
      }
    }
    @media(max-width: 500px){
      justify-content: center;
    }
`;
const RecognitionReceived = styled.div`
    font-family: ${MediumFont};
    font-size: ${({fontsize}) => fontsize ? fontsize : '30px'};
    padding-bottom: 15px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 20px;   
    align-items: center;   
    color: #005c87;
    margin-left: ${({fontsize}) => fontsize ? '18px' : '0px'};
    > p {
     color:#669db7;
     font-size: ${({fontsize}) => fontsize ? '12px' : '14px'};
     font-family: ${MediumFont};
     line-height: 20px;
     padding-top: 5px;
      @media(max-width: 1100px) {
        max-width: 100px;
      }
      @media(max-width: 750px){
        max-width: none;
      }
      @media(max-width: 500px){
        max-width: none;
        text-align: center;
      }
    }    
`;

const DepartmentDropdown = styled.div`
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    border-radius: 0px 0px 6px 6px;
    margin-top: 45px;
    >button{
      width: 100%;
      border: none;
      background: #005C870D;
      margin: -2px 2px;
      display: flex;
      justify-content: center;
    >button{
      border: none;
      background: none;
      height: 20px;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #0D4270;
      margin-top: 3px;
      div{
        >img{
        margin-left: 8px;
      }

      }
      >img{
        margin-left: 8px;
      }
    }
  }
 
`;

const RecognitionGiven = styled.div`
    font-family: ${MediumFont};
    font-size: 30px;
    margin-left: 0px;
    padding: 0px;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 20px;   
    align-items: center;   
    color: #0D4270;
    > p {
     opacity: 0.5;
     color: #282C37;
     font-size: 14px;
     font-family: ${MediumFont};
     padding-top: 5px;
     @media(max-width: 1100px) {
        max-width: 100px;
    }
    @media(max-width: 750px){
        max-width: none;
    }
    @media(max-width: 500px){
        max-width: none;
        text-align: center;
    }
    } 
`;
/* Styles for Department and Location*/
const TopRecognitionContainer = styled.div`
		float: ${({Size}) => Size && Size <= 3 ? 'left' : 'none'};
		margin: ${({Size}) => Size && Size <= 3 ? '10px 0px' : '0px'};
    width: 100%;
`;

const TopRecognized = styled.div`
   float: left;
   margin-right: 10px;
   width: ${({Size}) => Size && Size <= 3 ? '100%' : '100%'};
   margin: 10px 10px 10px 0;

   > div {
    display: ${({display}) => display};
   }
   ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: #403e3f;
  cursor: pointer;
  border-radius: 2.5px;
}

::-webkit-scrollbar-track {
  background: #c3c2c2;
  border-radius: 2.5px;
}
`;

const TopReceived = styled(TopRecognized) `
   margin-right: 0;
`;

const RecognitionHeader = styled.div`
  width: 100%;
  background: #FFFFFF;
  border-radius: 4px;
  color: #fff;
  padding:15px;
  font-family: ${BoldFont};
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 1px;
  display: flex;
  justify-content: space-between;
  >p{
    width: 157px;
    height: 24px;
    font-family: 'Rubik-Medium';
    font-size: 18px;
    line-height: 24px;
    color: #005c87;
    padding-top: 5px;
  }
  >div{
    border: none;
    background: none;
    .download_imgv2{
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
  }
`;

const RecognitionColumnHeader = styled.div`
  width: ${({width}) => width ? width : '100%'};
  background: #f2f7f9;
  padding: ${({padding}) => padding ? padding : '10px 10px 10px 0px'};
	float: left;
	margin-right: ${({marginRight}) => marginRight};
	height: ${({height}) => height};

	${({ display }) => display === 'flex' && css`
		display: ${({display}) => display};
  `}
`;

const Column = styled.div`
 width: ${({width}) => width ? width : '28%'};
 color: #005c87;
 display: flex;
 justify-content: center;
 &:last-child {
  text-align: center;
 }
 > p{
    text-align: center;
 }
 > span {
  font-size: 16px;
  font-family: Rubik-Regular;
  opacity: 0.5;
	text-align: center;
	display: block;
	margin-top: 1%;
 }
 > div{
  display: block;
		margin-right: 5%;
  > img {
    display: block;
		margin-right: 5%;
		height: 25px;
    width: ${({widthForImg}) => widthForImg};
 }

 }
 > img {
    display: block;
		margin-right: 5%;
		height: 25px;
    width: ${({widthForImg}) => widthForImg};
 }
 ${({ display }) => display && css`
		display: ${({display}) => display ? 'flex' : ''};
		justify-content: flex-start;
  `}
`;

const Row= styled.div`
  width: 100%;
  display: flex;
  justify-content: ${({display}) => display ? 'flex-start' : 'center'};
  align-items: center;
  padding: ${({display}) => display ? '15px 10px 15px 0px' : '15px 10px'};
  background-color: #fff;
	min-height: 80px;
	max-height: ${({height}) => height};
  font-size: 14px;
  color: #282C37;
  opacity:${({opacity}) => opacity && 0.5};
  font-family: ${BoldFont};
  border-top: ${({border}) => border ? border : 'none'};
  ${({ active }) => active && css`
    background-color: rgba(71,152,217,0.1);
  `}
`;

const NameContainer = styled.div`
  width: ${({width}) => width ? width : '28%'};
  font-family: ${RegularFont};
	font-size: 14px;
	display: flex;
  justify-content: ${({display}) => display ? 'flex-start' : 'center' };
	margin-right: ${({marginRight}) => marginRight};
  flex-direction: column;
  align-items: center;
  &:last-child {
   text-align: center;
   padding-left: 26px;
  }
  @media(max-width:500px) {
   float: none;
   margin: 0 auto;
   display: flex;
   justify-content: center;
   width: 100%;
   margin: 0 auto;
   padding: 5px 0;
  }
  > span{
  width: 103px;
  height: 14px;
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  background: linear-gradient(90deg, #006FBC -1.11%, #7F43FF 97.46%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  margin-top:'3px';
  width: 100%;
  display: block;
  white-space: nowrap;
  text-align: start;
  }
`;

const ProfileImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  float: left;
  margin-right: 5px;
  & > img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  @media(max-width: 500px) {
    float: none;
  }
`;

const Name = styled.div`
   float: left;
   width:  ${({width}) => width ? width : '100%'};
   font-family: ${BoldFont};
   font-size: 14px;
   color: #005c87;
	 max-width: ${({maxWidth}) => maxWidth ? maxWidth : '121px'};
   text-align: ${({textAlign}) => textAlign ? 'left' : 'center'};
	 margin: ${({margin}) => margin ? '0' : '10px 0 0 0'};
   ${({active}) => active && css`
    color: rgb(21,159,202);
	`}
	${({display}) => display && css`
		display: flex;
		justify-content: flex-start;
	`}
   @media (max-width: 550px) {
    text-align: center;
    width: ${({marginLeft}) => marginLeft ? 'auto' :  ''};
    display: inline-block;
    margin: 0 0 0 ${({marginLeft}) => marginLeft};
    float: none;
    min-width: 102px;
    max-width: none;
   }
`;

const Number = styled.div`
   float: left;
   width:  ${({width}) => width ? width : '100%'};
   font-family: ${MediumFont};
   font-size: 14px;
   color: #005C87;
	 max-width: ${({maxWidth}) => maxWidth ? maxWidth : '121px'};
   text-align: ${({textAlign}) => textAlign ? 'left' : 'center'};
	 margin: ${({margin}) => margin ? '0' : '10px 0 0 0'};
   ${({active}) => active && css`
    color: rgb(21,159,202);
	`}
	${({display}) => display && css`
		display: flex;
		justify-content: flex-start;
	`}
   @media (max-width: 550px) {
    text-align: center;
    width: ${({marginLeft}) => marginLeft ? 'auto' :  ''};
    display: inline-block;
    margin: 0 0 0 ${({marginLeft}) => marginLeft};
    float: none;
    min-width: 102px;
    max-width: none;
   }
`;


const RecognitionReceivedContainer =  styled.div`
 float: left;
 width: calc((100% - 30px) / 4);
 margin-right: 10px;
 margin-bottom: 10px;
 
 &:last-child {
  margin-right: 0;
 }
 
 @media (max-width: 1200px) {
  width: calc((100% - 20px) / 2);
 }
 
 @media (max-width: 506px) {
  width: 100%;
  margin- right: 0;
 }
`;

const RecognitionReceivedHeader = styled(RecognitionHeader)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  > img {
    float: left;
  }
  
  > span {
    float: left;
    margin-left: 10px;
  }
`;

const RecognisedUser = styled.div`
   width: 100%;
   padding: 15px 10px;
   background-color: #fff;
   float: left;
   display: flex;
   align-items: center;
   border-bottom: 1px solid #E0E0E7;
   &:last-child {
    border-bottom: none;
   }
`;

const UserProfileContainer = styled.div`
   width: 70%;
   float: left;
   display: block;
    > img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-right: 5px;
    float: left;
   }
   .wellnessChampion {
      margin: 0;
   }
   > span {
     float: left;
     font-family: ${MediumFont};
     font-size: 14px;
     color: #282C37;
     margin: 10px 0 0 0;
     white-space: nowrap; 
     overflow: hidden;
     text-overflow: ellipsis;
     max-width: 110px;
   }
   & > span:nth-child(3) {
    font-family: Rubik-Medium;
    font-size: 10px;
    color: #1E76AB;
    margin: 0 0 0 40px;
    width: calc(100% - 40px);
    display: block;
    margin-top: -16px;
    @media (max-width: 1200px) {
     float: left;
     margin: 0;
     max-width: none;
    }
   }
   
   .active {
     color: rgb(21,159,202);
   }
`;

const RecognitionFrequency = styled.div`
   width: 30%;
   float: left;
   text-align: center;
   
   > span {
    font-family: ${MediumFont};
    font-size: 14px;
    color: #282C37;
   }
`;

const RowWrapper = styled.div`
  float: left;
  background: rgba(156, 156, 156, 0.1);
  width: ${({width}) => width ? width : '100%'};
`;

const TotalRecognition = styled.div`
  font-family: ${MediumFont};
    font-size: 30px;
    color: #282C37;
    text-align: center;
    padding-bottom: 10px;
    position: absolute;
    width:100%;
    bottom: -50px;
    > p {
     word-break: break-all;
     opacity: 0.5;
     color: #282C37;
     font-size: 12px;
     font-family: ${BoldFont};
     }
`;
const IconWrapper = styled.div`
  display: inline-block;
  > img {
    display: none;
    }
`;
const ScrollableContainer = styled.div`
width: ${({width}) => width};
overflow-x: ${({divWidth}) =>  divWidth && divWidth > 5 ? 'scroll' : 'hidden'};
background-color: white;
padding-left: ${({padding}) => padding && padding <= 3 ? '0px' : '0px'};

	> div {
		width: ${({divWidth}) => divWidth <= 5 && '100%' }; 

		${({divWidth}) => divWidth > 5 && css`
			width: calc(170px * ${({divWidth}) =>  divWidth});
		`}
	}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: #403e3f;
  cursor: pointer;
  border-radius: 2.5px;
}

::-webkit-scrollbar-track {
  background: #c3c2c2;
  border-radius: 2.5px;
}
`;
const DisplayFlex = styled.div`
display: flex;
`;
const Container = styled.div`
width: ${({width}) => width ? width : '100%'};
background-color: white;
margin-right: ${({marginRight}) => marginRight};
padding-left: ${({padding}) => padding && padding <= 3 ?  '0px' : '0px'};
${({department}) => department && css `
	@media (max-width: 603px) {
		width: 45%;
	}
`}
`;

const BarGraphDesign = styled.div`
/* width: 1246px; */
left: 437px;
top: 435px;
background: #FFFFFF;
border-radius: 6px;
margin-bottom: 25px;
box-shadow: 0px 8px 16px 0px #005C8720;
.bar-graph-container{
  .title-container{
    margin: ${({isMargin}) => isMargin ? isMargin : '0px 0px 0px 25px'};
    ${'' /* margin: 0px 0px 0px 25px; */}
    padding-top: ${({isMargin}) => isMargin ? "12px" : '15px'};
    ${'' /* padding-top: 15px; */}
    display: flex;
    align-items:center;
    justify-content: space-between;
    .heading{
      height: 24px; 
      font-family: 'Rubik-Medium';
      font-size: 18px;
      line-height: 24px;
      display: flex;
      align-items: center; 
      color: #005c87;
      margin:0;
    }
    .download_imgV2{
      margin-right: 15px;
    }
  }
  hr-line{
    width: 1246px;
    height: 0px;
    left: 437px;
    top: 509px; 
    border: 1px solid rgba(0, 92, 135, 0.30);
  }
  .hrline-wrapper{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;
    right: 8px;
  }
  .x-axis-horizontal{ 
    border-top: 1px solid rgba(156, 156, 156, 0.5);
    width: 86%;
  }
  .graphs-x-axis{
    margin-top: 18px;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-left: 176px;

    .wrapperXaxis{
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-right: 59px;
    }
    .x-points{
      width: 16.6%;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      text-align: right;
      color: #005c87;
    }
  }
  .bottom-text{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #9C9C9C;
    padding-bottom: 20px;  
  }
}
`;

const DisplayGraphData = styled.div`
    display: flex;
    >h1{
      width: 15%;
      height: 20px;
      font-family: 'Rubik';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;  
      color: #005c87;
      padding-left: 25px;
    }
    div{
      >img{
      width: 20px;
      height: 20px;
      margin-right: 15px;
      margin-top: 10px;
    }

    }
    >img{
      width: 20px;
      height: 20px;
      margin-right: 15px;
      margin-top: 10px;
    }
    .y-axis{
      width: 1px;
      border-left: 1px dashed rgba(0,92,135,0.30);
    }
`;

const LineBackground = styled.div`
   width: 100%;
   height: 20px;
   margin-top: 10px;
   display: flex;
   .line{
    height: 20px;
    border-radius: 0px 6px 6px 0px;
    width: ${({percentWidth}) => percentWidth ? percentWidth+"%" : '0%'};
    background-color: ${({linecolor}) => linecolor ? linecolor : '#FFFFF'};
    }
    .showPercent{
      border-radius: 50%;
      width: 53px;
      height: 53px;
      background-color: ${({linecolor}) => linecolor ? linecolor : '#FFFFF'};
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      bottom: 17px;
      margin-left: 10px;
      padding: 17px;
      font-family: 'Rubik-Medium';
      font-weight: 700;
      font-size: 14px;
      color: #0D4270;
      color: white;
      box-shadow: -2px 25px 25px rgba(0, 0, 0, 0.1);
    }
    .show-box{
      border:1px solid ${({linecolor}) => linecolor ? linecolor : '#FFFFF'};
      border-radius:5px;
      height:fit-content;
      padding:5px 7px;
      ${'' /* width:135px; */}
      font-size:13px;
      margin-left:8px;
      position:relative;
      bottom:24px;
      text-align:left;

      .title{
        color:#669db7;
        line-height:15px;
      }
      .value{
        color:#669db7;
        line-height:15px;
        color: ${({linecolor}) => linecolor ? linecolor : '#FFFFF'};
        font-family:'Rubik-medium';
      }

    }
`;

const StyledModalV2 = styled(Modal)`
	.modal-dialog{
		display: flex;
    justify-content: center;
    /* overflow:scroll; */
    transform: ${({transform}) => transform && 'translate(0, 50vh) translate(0, -50%) !important'};
		.modal-content{
		  width: 1246px !important;
      max-height: 625px;
          border-radius: 10px;
          border: none;
		  .modal-header {
				border-bottom: ${({noBorder}) => noBorder ? noBorder : '1px solid #e5e5e5'};
                background: linear-gradient( 
                180deg,#52A8ED 0%,#8AD3F7 100%);
                padding: 13px;

				> div {
					display: flex;
					justify-content: center;

					> p{
						font-size: 20px;
						font-family: ${BoldFont};
						margin-top: 10px 0 0 0;
					}
				}
			}
			.modal-header .close {
				outline: none;
        float: right;
        margin-top: -21px;
        margin-right: 2px;
       color: white;
       font-size: 44px;
        opacity: 0.9;
			}
		}
	}
  /* @media (max-width: 500px) and (min-width: 320px) {
    .modal-dialog {
			width: 100%;

			.modal-content{
				width: 90%;
				margin-left: -6%;
			}
    }
  } */
`;

const Border = styled.div`
width:1px;
height:auto;
border-left: 1px dashed rgba(0, 92, 135, 0.30);
position:relative;
right: ${({left}) => left ? left : '0px'};
`;

export {TopRecognitionContainer, TopRecognized, TopReceived, RecognitionHeader, RecognitionColumnHeader, Column, Row, NameContainer, ProfileImageContainer, Name, RecognitionReceivedContainer, RecognitionReceivedHeader, RecognisedUser, UserProfileContainer, RecognitionFrequency, RowWrapper,
  MainContainer, GraphContainer, CompanyHeader, TopDepartmentGraph, BottomLabelDonutContainer, NoData, PieChartContainer, CompanyName, RecognitionReceived, RecognitionGiven, TotalRecognition, IconWrapper,
  ScrollableContainer, Container, DisplayFlex, DepartmentDropdown, Number, BarGraphDesign, DisplayGraphData, LineBackground,StyledModalV2, Border};